import { BrowserRouter, Routes, Route } from "react-router-dom";
import { publicRoutes, privateR } from "./routes";
import PrivateRoute from "./routes/privateRoute";
import PublicRoute from "./routes/publicRoute";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {publicRoutes.map((value) => (
          <Route path={value.path} element={<PublicRoute />} key={""}>
            <Route path={value.path} element={value.component} />
          </Route>
        ))}
        {privateR.map((value) => (
          <Route path={value.path} element={<PrivateRoute />} key={""}>
            <Route path={value.path} element={value.component} />
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
