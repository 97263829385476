import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { PlusCircle } from "react-bootstrap-icons";

const CustomToolbar = ({ handleCloseUpdateInfo, setIsCreate }) => {
  const handleClick = () => {
    setIsCreate(true);
    handleCloseUpdateInfo();
  };

  return (
    <>
      <Tooltip title={"custom icon"}>
        <IconButton style={{}} onClick={handleClick}>
          <PlusCircle style={{}} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default CustomToolbar;
