import { Row, Col, Dropdown, Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Logout } from "../../../redux/toolkit/auth/operation";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import BASE_URL from "../../../constants/baseURL";
import { Power } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import "./index.css";

const Header = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const token: any = localStorage.getItem("token");
  const userLogout: any = useSelector((state: RootState) => state.qrDetails);

  const handleToggle = (isOpen: any) => {
    setShow(isOpen);
  };
  const handleButtonClick = () => {
    setLoading(true);
    dispatch(Logout(token)).then(() => setLoading(false));
  };

  const handleDropdownItemClick = (event: any) => {
    event.stopPropagation();
    handleButtonClick();
  };
  const initial = firstName.charAt(0).toUpperCase();
  const getUserDetail = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (userId) {
        const res = await axios.get(`${BASE_URL}user/getUser/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("DATA ", res?.data?.data);
        if (res?.data?.data) {
          const data = res?.data?.data;
          setFirstName(data?.firstName);
          setLastName(data?.lastName);
        }
      }
    } catch (error) {
      console.log("Error Occured for User Detail ", error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);
  
  useEffect(() => {
    if (userLogout.data === "") {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.clear();
      navigate("/");
    }
  }, [userLogout]);
  return (
    <Row
      style={{
        backgroundColor: "white",
        color: "white",
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 0px",
      }}
    >
      <Col className="border-left d-flex border-black  justify-content-end px-4">
        <Dropdown show={show} onToggle={handleToggle}>
          <Dropdown.Toggle
            as="div"
            id="dropdown-custom-components"
            className="circle"
          >
            {initial}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as="div"
              style={{ pointerEvents: "none", fontWeight: 500 }}
            >
              {`${firstName} ${lastName}`}
            </Dropdown.Item>
            <Dropdown.Item
              as="div"
              onClick={handleDropdownItemClick}
              style={{ display: "flex", alignItems: "center" }}
            >
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" />
                  <span style={{ marginLeft: "5px" }}>Logout</span>
                </>
              ) : (
                <>
                  <Power color="black" />
                  <span style={{ marginLeft: "5px" }}>Logout</span>
                </>
              )}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default Header;
