import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import type { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ArrowDown as ArrowDownIcon } from '@phosphor-icons/react/dist/ssr/ArrowDown';
import { ArrowUp as ArrowUpIcon } from '@phosphor-icons/react/dist/ssr/ArrowUp';
import { CurrencyDollar as CurrencyDollarIcon } from '@phosphor-icons/react/dist/ssr/CurrencyDollar';

export interface BudgetProps {
  diff?: number;
  trend: 'up' | 'down';
  sx?: SxProps;
  value: string;
  title:string
  icon:any
}

export function Budget({ diff, trend, sx,title, value , icon}: BudgetProps) {
  const TrendIcon = trend === 'up' ? ArrowUpIcon : ArrowDownIcon;
  const trendColor = trend === 'up' ? 'green' : 'red';

  return (
    <Card sx={{ height: '100%', borderRadius: "20px", boxShadow: '0 5px 22px 0 rgba(0, 0, 0, 0.04),0 0 0 1px rgba(0, 0, 0, 0.06)' }}>
      <CardContent>
        <Stack spacing={3}>
          <Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
            <Stack spacing={1}>
            {/* <Typography color="text.secondary" variant="caption" sx={{fontFamily:'Montserrat'}}>
                User 
              </Typography> */}
              <Typography sx={{fontFamily:'Montserrat',fontSize:'12px',fontWeight:600, letterSpacing:'0.05rem'}} color="text.secondary" variant="overline">{title}</Typography>
              <Typography variant="h4" sx={{fontFamily:'Montserrat'}}>{value}</Typography>
            </Stack>
            <Avatar sx={{ backgroundColor: '#635bff', height: '56px', width: '56px' }}>
              {icon}
            </Avatar>
          </Stack>
          {diff ? (
            // remove visibility "hidden property to see element"
            <Stack sx={{ alignItems: 'center',visibility:"hidden" }} direction="row" spacing={2}>
              <Stack sx={{ alignItems: 'center' }} direction="row" spacing={0.5}>
                <TrendIcon color={trendColor} fontSize="1.25rem" />
                <Typography color={trendColor} sx={{fontFamily:'Montserrat'}} variant="body2">
                  {diff}%
                </Typography>
              </Stack>
              <Typography color="text.secondary" variant="caption" sx={{fontFamily:'Montserrat',fontWeight:600}}>
                Since last month
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
}
