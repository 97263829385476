import MUIDataTable, { MUIDataTableColumn } from "mui-datatables";
import { Container, Navbar, Row, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import BASE_URL from '../constants/baseURL'
import DefaultSidebar from '../components/sidebar';
import Col from 'react-bootstrap/Col';
import { Chip } from '@mui/material';
import { Button } from "react-bootstrap"
import Header from "../components/common/header";
import ProgressBar from "../components/common/progressbar";
import {Typography} from "@mui/material";

const Accounts = () => {

    const [accountsList, setAccountsList]: any = useState([]);
    const [loadingSuspend, setLoadingSuspend] = useState(false)
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [progress, setProgress] = useState(0);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const Token: any = localStorage.getItem("token")





    async function deleteUsersAccountsAPI(B4BUUID: string, inactive: Boolean) {
        var body;

        setLoadingSuspend(true);
        if (inactive) {
            body = { "inactive": false }
        } else {
            body = { "inactive": true }
        }
        const response: any = await axios.patch(`${BASE_URL}activeUser/delete-account/${B4BUUID}`, {
            ...body
        });
        
        return response.status;

    }


    const handleDeleteButtonClick = async (accountId: string, isInactive: boolean) => {

        try {
            const status = await deleteUsersAccountsAPI(accountId, isInactive);
            if (status === 200) {
                await fetchAllAccountsAPI();
                setLoadingSuspend(false);
            }
        } catch (error) {
            console.error("Error deleting account:", error);
        }
    };



    const accountsHeader: MUIDataTableColumn[] = [
        { name: "_id", label: "ID", options: { display: 'excluded' } },

        { name: "accountName", label: "Account Name" },
        { name: "IBAN", label: "IBAN" },
        {
            name: "currency", label: "Currency"
        },
        {
            name: "inactive", label: "Status", options: {
                customBodyRender: (data: any, dataIndex: any) => {


                    return (

                        <Chip label={dataIndex.rowData[4] ? "Not Active" : "Active"} color={!dataIndex.rowData[4] ? "primary" : "secondary"} />
                    );
                }
            }
        },
        { name: "createdAt", label: "Created" },

        {
            name: "swiftCode", label: "Swift Code",
        },
        {
            name: "bank", label: "Bank"
        },
        {
            name: "Actions", label: "Actions", options: {
                customBodyRender: (data: any, dataIndex: any) => {
                    const accountId = dataIndex.rowData[0];
                    const isInactive = dataIndex.rowData[4];

                    return (
                        loadingSuspend ? <Spinner animation={"border"} size='sm' /> : <Button
                            style={isInactive ? { backgroundColor: 'blue', borderColor: 'blue', margin: 10, fontSize: 10, fontWeight: 'bold' } : { backgroundColor: 'red', borderColor: 'red', margin: 10, fontSize: 10, fontWeight: 'bold' }}
                            onClick={() => handleDeleteButtonClick(accountId, isInactive)}
                        >
                            {isInactive ? "Reopen" : "Suspend"}
                        </Button>
                    );
                }
            }

        }
    ]

    async function fetchAllAccountsAPI() {
        setLoadingUsers(true);
        setInitialLoading(true);
        setProgress(0);
        const updateProgress = (event: any) => {
            if (event.total) {
              setProgress(Math.round((event.loaded / event.total) * 100));
            }
          };
      
        try {
            const response: any = await axios.get(`${BASE_URL}activeUser/getAllAccounts`, {
            headers: {
                "Authorization": `Bearer ${Token}`
            },
            onDownloadProgress: updateProgress,
            onUploadProgress: updateProgress,
        });
            setAccountsList(response.data.data);
            console.log("AccountLList", accountsList);
        } catch (error) {
            console.error("Error fetching users:", error);
            
        } finally {
            setProgress(100);
            setLoadingUsers(false);
            setInitialLoading(false);
          }
        
    }

    useEffect(() => {
        fetchAllAccountsAPI()
    }, [])


    return (
        <Container fluid className="App">
            <Row>
                <Col style={{
                    backgroundColor: "white",
                    minHeight: "",
                    color: "white"
                }} lg={2}  >

                    <DefaultSidebar />

                </Col>
                <Col style={{
                    backgroundColor: "#ececec"
                }} sm={12} xl={10} xs={12} md={12} lg={10} >
                    <Header/>
                {initialLoading || loadingUsers ? (
                <div
                className="d-flex justify-content-between align-items-center"
                style={{ alignItems: "flex-start" }}
                >
                <ProgressBar progress={progress} />
                <Typography>{progress}%</Typography>
                </div>
                ) : !loadingUsers && accountsList.length === 0 ? (
                <h3>Oops! Something went wrong</h3>
                ) : (
                        <div style={{ margin: 10 }}>
                         <MUIDataTable
                            title={"Accounts List"}
                            data={accountsList}
                            columns={accountsHeader}
                        />
                        </div>
                )}
                </Col>
            </Row >
        </Container>
    );
}

export default Accounts;
