import axios, { AxiosRequestConfig, AxiosInstance,AxiosHeaders } from "axios";
import BASE_URL from '../constants/baseURL'


const api = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem("token");
  const tempHeader= config.headers as any;
  tempHeader["Authorization"] = token ? `Bearer ${token}` : undefined;
  config.headers = tempHeader;
  
  // (config.headers as AxiosHeaders).set("Authorization", token ? `Bearer ${token}` : undefined);
  // config.headers["Authorization"] = token ? `Bearer ${token}` : undefined;
  return config;
});

export default api;

