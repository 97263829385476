import axios from "axios";
import BASE_URL from  '../constants/baseURL'

export async function apiHelper(
  url: string,
  method: string,
  body: object = {},
  headers: object = {"Content-Type": "application/json"}
) {
  try {
    url = `${BASE_URL}${url}`;
    const config: any = { method, url, headers };
    if (method == "post") {
      config["data"] = body;
    }
    const response = await axios(config);
    return response;
  } catch (e: any) {
    throw new Error(e.response.data.message);
  }
}
