import React, { useEffect, useState } from "react";
import { Row, Col, Navbar, Modal, Button, Form, Container } from 'react-bootstrap'
import DefaultSidebar from '../components/sidebar';
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import BASE_URL from '../constants/baseURL'
import { Chip } from '@mui/material';
import CustomToolbar from "../components/customtoolbar/customtoolbar";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Header from "../components/common/header";
import ProgressBar from "../components/common/progressbar";
import {Typography} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Roles = () => {

    const [personName, setPersonName] = React.useState([]);
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [showUpdateInfo, setShowUpdateInfo] = useState(false);
    const [updateObj, setUpdateObj] = useState({
        name: '',
        slug: '',
        permissions: []
    })
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [progress, setProgress] = useState(0);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const theme = useTheme();
    const [isCreate, setIsCreate] = useState(false);

    const options = {
        customToolbar: () => {
            return (
                <CustomToolbar handleCloseUpdateInfo={handleCloseUpdateInfo} setIsCreate={setIsCreate} />
            );
        }
    };


    const Headers = [{ name: "name", label: "Name" }, { name: "slug", label: "Slug" },
    {
        name: "Update Info", label: "Update Info", options: {
            customBodyRenderLite: (dataIndex: any) => {
                return <><Chip color='info' onClick={() => { handleShowUserInfoUpdate(roles[dataIndex]) }} label="Update" /></>
            }
        }
    }]

    function getStyles(name: any, personName: any, theme: any) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const handleCloseUpdateInfo = () => {
        if (showUpdateInfo === true) {
            setUpdateObj({
                name: '',
                slug: '',
                permissions: []
            })
        }
        setShowUpdateInfo(!showUpdateInfo)
    }

    const onChangeText = (key: string, e: any) => {
        const obj = {
            ...updateObj,
            [key]: e.target.value,
        }
        setUpdateObj(obj)
    }

    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        const obj = {
            name: updateObj?.name,
            slug: updateObj?.slug,
            permissions: value
        }
        setUpdateObj(obj)
    };

    const getRoles = async () => {
        setLoadingUsers(true);
        setInitialLoading(true);
        setProgress(0);
        const updateProgress = (event: any) => {
            if (event.total) {
              setProgress(Math.round((event.loaded / event.total) * 100));
            }
          };
        try {
            let res = await axios.get(`${BASE_URL}role`,{
                onDownloadProgress: updateProgress,
                onUploadProgress: updateProgress, })
            if (res?.status === 200 && res?.data?.data)
                setRoles(res.data.data)
        } catch (error) {
            console.log('Error ', error)
        } finally {
            setProgress(100);
            setLoadingUsers(false);
            setInitialLoading(false);
        }
    }

    const getPermissions = async () => {
        try {
            let res = await axios.get(`${BASE_URL}permission`)
            if (res?.status === 200 && res?.data?.data)
                setPermissions(res.data.data)
        } catch (error) {
            console.log('Error ', error)
        }
    }

    const createRole = async ({ }) => {
        try {
            const uPermission = updateObj?.permissions.map((el) => {
                const parse = JSON.parse(el);
                return parse._id;
            })

            let obj = {
                name: updateObj?.name,
                slug: updateObj?.slug,
                permissions: uPermission
            }

            let res = await axios.post(`${BASE_URL}role`, obj)
            if (res) {
                setIsCreate(false);
                getRoles();
                handleCloseUpdateInfo();
            }

        } catch (error) {
            console.log('Error ', error)
        }
    }

    const updateRole = async ({ }) => {
        try {
            const uPermission = updateObj?.permissions.map((el) => {
                const parse = JSON.parse(el);
                return parse._id;
            })

            let obj = {
                name: updateObj?.name,
                slug: updateObj?.slug,
                permissions: uPermission
            }

            let res = await axios.put(`${BASE_URL}role/id`, obj)
            if (res) {
                getRoles();
                handleCloseUpdateInfo();
            }
        } catch (error) {
            console.log('Error ', error)
        }
    }

    const handleShowUserInfoUpdate = (el: any) => {
        if (el) {
            const stringifyPermissions = el?.permissions.map((e: any) => JSON.stringify(e))
            const obj = {
                name: el?.name,
                slug: el?.slug,
                permissions: stringifyPermissions
            }
            setUpdateObj(obj)
        }
        handleCloseUpdateInfo()
    }

    useEffect(() => {
        getRoles();
        getPermissions();
    }, [])


    return (
        <Container fluid className="App">
            <Row>
                <Col style={{
                    backgroundColor: "white",
                    minHeight: "",
                    color: "white"
                }} lg={2}  >
                    <DefaultSidebar />
                </Col>

                <Col style={{
                    backgroundColor: "#ececec"
                }} sm={12} xl={10} xs={12} md={12} lg={10} >

                    <Header/>

                    {initialLoading || loadingUsers ? (
            <div
              className="d-flex justify-content-between gap-2  align-items-center"
              style={{ alignItems: "flex-start" }}
            >
              <ProgressBar progress={progress} />
              <Typography>{progress}%</Typography>
            </div>
          ) : !loadingUsers && roles.length === 0 ? (
            <h3>Oops! Something went wrong</h3>
          ) : (


                    <div style={{ margin: 10 }}>
                        <MUIDataTable
                            title={"Roles List"}
                            data={roles}
                            columns={Headers}
                            options={options}
                        />
                    </div>
                      )}
                </Col>


                <Modal show={showUpdateInfo} onHide={handleCloseUpdateInfo}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isCreate ? 'Create Role' : 'Update Role'}</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={() => { }}>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" onChange={(e) => onChangeText('name', e)} required={true} defaultValue={updateObj?.name} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="slug">
                                <Form.Label>Slug Name</Form.Label>
                                <Form.Control type="text" placeholder="Slug" onChange={(e) => onChangeText('slug', e)} required={true} defaultValue={updateObj?.slug} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="slug">
                                <Form.Label>Permissions</Form.Label>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    fullWidth
                                    multiple
                                    value={updateObj?.permissions}
                                    onChange={handleChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value: any) => {
                                                const parseValue = JSON.parse(value)
                                                return <Chip key={parseValue?._id} label={parseValue?.name} />
                                            }
                                            )}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {permissions.map((el: any) => (
                                        <MenuItem
                                            key={el?._id}
                                            value={JSON.stringify(el)}
                                            style={getStyles(el?.name, personName, theme)}
                                        >
                                            {el?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseUpdateInfo} type="button">
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => isCreate ? createRole({}) : updateRole({})}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

            </Row>
        </Container>
    )
}

export default Roles;