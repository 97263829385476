import React from "react";
import "./index.css";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { LOGO_URL } from "../../constants/stringConstants";
import { PathStrConst } from "../../routes/strconstpath";

const DefaultSidebar = (props: any) => {
  const linkData = [
    { path: PathStrConst.home, label: "Home", disabled: false },
    { path: "/accounts", label: "Accounts", disabled: false },
    { path: "/roles", label: "Roles", disabled: true },
    { path: "/permissions", label: "Permissions", disabled: true },
    { path: PathStrConst.users, label: "Users", disabled: false },
    { path: PathStrConst.merchants, label: "Merchants", disabled: true },
    { path: "/", label: "Settings", disabled: true },
    { path: "/", label: "Subscription", disabled: true },
  ];

  return (
    <Navbar sticky="top" className="navbar-main min-vh-100-lg flex-lg-column flex-sm-row" expand="lg">
      <LinkContainer to={PathStrConst.home}>
        <img className="width" src={LOGO_URL} alt="logo for Array" />
      </LinkContainer>

      <Navbar.Toggle className="navbartogglers" aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="d-flex flex-column pt-4">
          {linkData.map((link, index) => (
            <LinkContainer key={index} to={link.path} activeClassName="activeClass">
              <Nav.Link className={`NavLink ${link.disabled ? 'disabled-link' : 'active-link'}`} onClick={link.disabled ? (e) => e.preventDefault() : undefined}>
                {link.label}
              </Nav.Link>
            </LinkContainer>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default DefaultSidebar;
