import React, { useEffect, useState } from 'react'
import DefaultSidebar from '../../components/sidebar';
import { Container, Form, Modal, Navbar, Row, Spinner, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import api from '../../api helper/api';
import apiEndpoints from '../../api helper/apiEndpoints';
// import {Item} from "@mui/material"
import HomePage from './IndexPage'
import { Power } from 'react-bootstrap-icons'
import { AppDispatch, RootState } from '../../redux/store';
import { Logout } from '../../redux/toolkit/auth/operation';
import Header from '../../components/common/header';

const Home = () => {
    const [userDetails, setUserDetails]: any = useState({})
    const [loading, setLoading] = useState(false);
    const token: any = localStorage.getItem("token");
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        // getdetails();
    }, [])

    const getdetails = async () => {
        try {
            const data = await api.get(apiEndpoints.UserCountDetails);
            setUserDetails(data.data)
            console.log("=>", data.data)
        } catch (error) {
            console.log(error)
        }

    }

    const handleButtonClick = () => {
        setLoading(true)
        dispatch(Logout(token)).then(() => setLoading(false));
    };

    const CustomCard = ({ title = "", count = 0 }) => {
        return <Grid item xs={12} sm={4} md={6} lg={4}>
            <Box sx={{ minWidth: 275 }} pr={1}>
                <Card variant="outlined" sx={{ borderRadius: 2 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14, fontFamily: 'Montserrat' }} color="text.secondary" gutterBottom>
                            User Details
                        </Typography>
                        <Typography sx={{ fontFamily: 'Montserrat' }} variant="h4" component="div" color="text.secondary">
                            {title}
                        </Typography>
                        <Typography sx={{ mb: 1.5, fontFamily: 'Montserrat' }} variant="h5" color="text.secondary">
                            {count}
                        </Typography>
                        <Typography variant="h6" sx={{ fontFamily: 'Montserrat' }}>
                            well meaning and kindly.
                            {/* <br />
{'"a benevolent smile"'} */}
                        </Typography>
                    </CardContent>
                    {/* <CardActions>
<Button size="small">Button</Button>
</CardActions> */}
                </Card>
            </Box>
        </Grid>
    }

    return (
        <Container fluid className="App">
            <Row>
                <Col style={{
                    backgroundColor: "white",
                    minHeight: "",
                    color: "white",
                }} lg={2} >

                    <DefaultSidebar />

                </Col>
                <Col style={{
                    backgroundColor: "#ececec"
                }} sm={12} xl={10} xs={12} md={12} lg={10} >

                   <Header/>
                    <div style={{ margin: 15 }}>
                        <HomePage />
                    </div>


                </Col>
            </Row>
            {/* <Grid container spacing={2}>
                <Grid item lg={2}>
                    <DefaultSidebar />
                </Grid>

                <Grid item lg={10} mt={1} style={{ backgroundColor: 'rgb(236, 236, 236)', minHeight: '100vh' }}>
                    <HomePage/>
    


                </Grid>

            </Grid> */}

        </Container>
    )
}

export default Home