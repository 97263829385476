import { LoginResponse, ProfileDetailsResponse, QrResponse } from "./interface";
import { createSlice } from "@reduxjs/toolkit";
import {
  ForgotPasswordOp,
  Logout,
  QrVerification,
  ResetPasswordOp,
  UserLogin,
  UserRegistration,
} from "./operation";

const initialStateProfile = {
  data: {},
} as ProfileDetailsResponse;

const initialStateLogin = {
  data: {},
} as LoginResponse;

const initialStateQr = {
  data: {},
} as QrResponse;

const profileDetailsSlice = createSlice({
  name: "profileDtails",
  initialState: initialStateProfile,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UserRegistration.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
  },
});

const loginDetailsSlice = createSlice({
  name: "loginDetails",
  initialState: initialStateLogin,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UserLogin.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});

const qrDetailsSlice = createSlice({
  name: "qrDetails",
  initialState: initialStateQr,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(QrVerification.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
    builder.addCase(Logout.fulfilled, (state: any) => {
      state.data = "";
    });
  },
});

const forgotPassDetailsSlice = createSlice({
  name: "forgotPassDetails",
  initialState: initialStateQr,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ForgotPasswordOp.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
  },
});

const resetPassDetailsSlice = createSlice({
  name: "resetPassDetails",
  initialState: initialStateQr,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ResetPasswordOp.fulfilled, (state: any, action) => {
      state.data = action.payload;
    });
  },
});

export default {
  profileDetailsSlice,
  loginDetailsSlice,
  qrDetailsSlice,
  forgotPassDetailsSlice,
  resetPassDetailsSlice,
};
