import { persistReducer } from "redux-persist";
import {
  forgotPassDetailsConfig,
  loginDetailsConfig,
  profileDetailsConfig,
  qrDetailsConfig,
  resetPassDetailsConfig,
} from "../toolkit/auth/persistStore";
import ProfileReducer from "../toolkit/auth/reducer";


const reducers = {
  loginDetails: persistReducer(
    loginDetailsConfig,
    ProfileReducer.loginDetailsSlice.reducer
  ),
  forgotPassDetails: persistReducer(
    forgotPassDetailsConfig,
    ProfileReducer.forgotPassDetailsSlice.reducer
  ),
  resetPassDetails: persistReducer(
    resetPassDetailsConfig,
    ProfileReducer.resetPassDetailsSlice.reducer
  ),
  profileDetails: persistReducer(
    profileDetailsConfig,
    ProfileReducer.profileDetailsSlice.reducer
  ),
  qrDetails: persistReducer(
    qrDetailsConfig,
    ProfileReducer.qrDetailsSlice.reducer
  ),

};

export default reducers;
