import React, { useEffect, useState } from "react";
import { LOGO_URL } from "../constants/stringConstants";
import InputComponent from "../components/input";
import {
  errorMsgs,
  regexStrConst,
} from "../constants/stringConstants";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserLogin } from "../redux/toolkit/auth/operation";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { useSelector } from "react-redux";
import "./index.css";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [password, setPassword] = useState("");
  const [inputError, setInputError] = useState("");
  const [passError, setPassError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const loginDetailss = useSelector((state: RootState) => state.loginDetails);

  useEffect(() => {
    if (loginDetailss && Object.keys(loginDetailss.data).length !== 0) {
      navigate("/verification", { state: loginDetailss.data });
      localStorage.setItem("userEmail", email);
      localStorage.setItem("userPhone", phoneNo);
    }
  }, [loginDetailss]);

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (regexStrConst.phoneNumberRegex.test(item)) {
      setInputError("");
      setPhoneNo(item);
      setEmail("");
    } else if (regexStrConst.emailRegex.test(item)) {
      setInputError("");
      setEmail(item.toLowerCase());
      setPhoneNo("");
    } else {
      setInputError(errorMsgs.emptyInput);
    }
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const item = event.target.value;
    if (regexStrConst.passwordRegex.test(item)) {
      setPassError("");
    } else {
      setPassError(errorMsgs.emptyPassword);
    }
    setPassword(item);
  };

  const validate = () => {
    if (email === "" && phoneNo === "") setInputError(errorMsgs.emptyInput);
    if (password === "") setPassError(errorMsgs.emptyPassword);

    return (email === "" && phoneNo === "") ||
      password === "" ||
      inputError ||
      passError
      ? false
      : true;
  };

  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      setLoading(true);
      const payload = {
        email: email,
        phoneNo: phoneNo,
        password: password,
      };
      dispatch(UserLogin(payload)).then(() => {
        setLoading(false);
      });
    }
  };

  return (
    <div className="Enter">
      <div className="left">
        <div className="logo-box">
          <div className="logo-wrapper">
            <img
              className="logo"
              style={{ width: 208, height: 80 }}
              src={LOGO_URL}
              alt="One Money Way"
            ></img>
          </div>
        </div>
        <div className="left-title-box">
          <h2 className="left-title">The Payment </h2>
          <h2 className="left-title-second">Platform</h2>
          <h2 className="left-title-third">for SMEs</h2>
        </div>
        <div className="welcome-box">
          <p className="welcome">Welcome</p>
          <p className="after-welcome">Just a couple of clicks and we begin</p>
        </div>
        <div className="circle-box">
          <div className="blue-circle"></div>
          <div className="white-circle"></div>
        </div>
      </div>
      <div className="right">
        <h2 className="right-title">Enter</h2>
        <p className="right-text">Please login to access your account</p>
        <form className="enter-form" onSubmit={handleSubmit}>
          <p className="email-text">E-mail or phone number</p>
          <InputComponent
            type="text"
            placeholder="Enter e-mail or phone number"
            value={email ? email : phoneNo}
            onBlur={() =>
              email === "" && phoneNo === ""
                ? setInputError(errorMsgs.emptyInput)
                : setInputError("")
            }
            onChange={onChangeEmail}
            errorMsg={inputError}
          />
          <p className="pass-text">Password</p>
          <InputComponent
            type="password"
            className="pass-inp"
            placeholder="Enter password"
            value={password}
            onBlur={() =>
              password === ""
                ? setPassError(errorMsgs.emptyPassword)
                : setPassError("")
            }
            onChange={onChangePassword}
            errorMsg={passError}
          />
          <Link className="forgot-link" to="/forgotPassword">
            Forgot your password?
          </Link>
          <div className="btn-box">
            <button type="submit" className="sign-btn">
              {loading ? <Spinner animation={"border"} /> : "Sign In"}
            </button>
         
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
