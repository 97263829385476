import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
// import dayjs from 'dayjs';

import { Budget } from './budget';
import { LatestOrders } from './latest-orders';
import { LatestProducts } from './latest-products';
import { Sales } from './sales';
import { TasksProgress } from './tasks-progress';
import { TotalCustomers } from './total-customers';
import { TotalProfit } from './total-profit';
import { Traffic } from './traffic';
import api from '../../api helper/api';
import apiEndpoints from '../../api helper/apiEndpoints';
import { Bank,Money,User, Users, Check,UserMinus,UserCircleMinus,Vault  } from '@phosphor-icons/react';



export default function Page() {
  const [userDetails, setUserDetails]: any = useState({})
  useEffect(() => {
    getdetails();
  }, [])

  const getdetails = async () => {
    try {
      const data = await api.get(apiEndpoints.UserCountDetails);
      setUserDetails(data.data)
      console.log("=>", data.data)
    } catch (error) {
      console.log(error)
    }

  }
  // <CustomCard title='All' count={userDetails ? userDetails.allUserssCount: 0}/>
  //                 <CustomCard title='Registered banks' count={userDetails ? userDetails.allAccountsWithBanks: 0}/>
  //                 <CustomCard title='In-active banks' count={userDetails ? userDetails.inActiveAccountsWithBanks: 0}/>
  //                 <CustomCard title='Not varified' count={userDetails ? userDetails.notVarifiedUsers: 0}/>
  //                 <CustomCard title='Varified' count={userDetails ? userDetails.varifiedUsers: 0}/>
  //                 <CustomCard title='Active Banks' count={userDetails ? userDetails.activeAccountsWithBanks: 0}/>

  return (
    <Grid container spacing={3}>
      <Grid lg={3} sm={6} xs={12}>
        <Budget 
            diff={12} 
            trend="up" 
            title='Total User' 
            value={userDetails ? userDetails.allUserssCount : 0}
            icon={<Users/>}
           />
      </Grid>

      <Grid lg={3} sm={6} xs={12}>
        <Budget diff={16} trend="down" title="Registered bank account" value={userDetails ? userDetails.allAccountsWithBanks : 0} icon={<Bank/>}
           />
      </Grid>

      <Grid lg={3} sm={6} xs={12}>
        <Budget 
        diff={12} 
        trend="up" 
        title='Active Bank Account' 
        value={userDetails ? userDetails.activeAccountsWithBanks: 0}
        icon={<Vault/>}
          sx={{height: '100%', borderRadius: "20px", boxShadow: '0 5px 22px 0 rgba(0, 0, 0, 0.04),0 0 0 1px rgba(0, 0, 0, 0.06)' }} />
      </Grid>

      <Grid lg={3} sm={6} xs={12}>
        <Budget diff={16} trend="down" title="In-active bank account" value={userDetails ? userDetails.inActiveAccountsWithBanks : 0} icon={<UserCircleMinus/>}/>
      </Grid>

      <Grid lg={3} sm={6} xs={12}>
        <Budget 
           diff={12} 
           trend="up" 
           title='verified user' 
           value={userDetails ? userDetails.varifiedUsers : 0}
           icon={<Check/>}
           />
      </Grid>
      <Grid lg={3} sm={6} xs={12}>
        <Budget diff={16} trend="down" title="Not verified user" value={userDetails ? userDetails.notVarifiedUsers : 0} icon={<UserMinus/>}/>
      </Grid>



      {/* <Grid lg={3} sm={6} xs={12}>
        <TasksProgress sx={{ height: '100%', borderRadius: "20px", boxShadow: '0 5px 22px 0 rgba(0, 0, 0, 0.04),0 0 0 1px rgba(0, 0, 0, 0.06)' }} value={75.5} />
      </Grid> */}


      {/* <Grid lg={3} sm={6} xs={12}>
        <TotalProfit sx={{ height: '100%', borderRadius: "20px", boxShadow: '0 5px 22px 0 rgba(0, 0, 0, 0.04),0 0 0 1px rgba(0, 0, 0, 0.06)' }} value="$15k" />
      </Grid> */}


      <Grid lg={12} xs={12}>
        <Sales
          chartSeries={[
            { name: 'This year', data: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20] },
            { name: 'Last year', data: [12, 11, 4, 6, 2, 9, 9, 10, 11, 12, 13, 13] },
          ]}
          sx={{ display:"none", height: '100%', borderRadius: "20px", boxShadow: '0 5px 22px 0 rgba(0, 0, 0, 0.04),0 0 0 1px rgba(0, 0, 0, 0.06)' }}
        />
      </Grid>


      {/* <Grid lg={4} md={6} xs={12}>
        <Traffic chartSeries={[63, 15, 22]} labels={['Desktop', 'Tablet', 'Phone']} sx={{ height: '100%', borderRadius: "20px", boxShadow: '0 5px 22px 0 rgba(0, 0, 0, 0.04),0 0 0 1px rgba(0, 0, 0, 0.06)' }} />
      </Grid> */}


      {/* <Grid lg={4} md={6} xs={12}>
        <LatestProducts
          products={[
            {
              id: 'PRD-005',
              name: 'Soja & Co. Eucalyptus',
              image: '/assets/product-5.png',
              updatedAt: dayjs().subtract(18, 'minutes').subtract(5, 'hour').toDate(),
            },
            {
              id: 'PRD-004',
              name: 'Necessaire Body Lotion',
              image: '/assets/product-4.png',
              updatedAt: dayjs().subtract(41, 'minutes').subtract(3, 'hour').toDate(),
            },
            {
              id: 'PRD-003',
              name: 'Ritual of Sakura',
              image: '/assets/product-3.png',
              updatedAt: dayjs().subtract(5, 'minutes').subtract(3, 'hour').toDate(),
            },
            {
              id: 'PRD-002',
              name: 'Lancome Rouge',
              image: '/assets/product-2.png',
              updatedAt: dayjs().subtract(23, 'minutes').subtract(2, 'hour').toDate(),
            },
            {
              id: 'PRD-001',
              name: 'Erbology Aloe Vera',
              image: '/assets/product-1.png',
              updatedAt: dayjs().subtract(10, 'minutes').toDate(),
            },
          ]}
          sx={{ height: '100%' }}
        />
      </Grid> */}


      {/* <Grid lg={8} md={12} xs={12}>
        <LatestOrders
          orders={[
            {
              id: 'ORD-007',
              customer: { name: 'Ekaterina Tankova' },
              amount: 30.5,
              status: 'pending',
              createdAt: dayjs().subtract(10, 'minutes').toDate(),
            },
            {
              id: 'ORD-006',
              customer: { name: 'Cao Yu' },
              amount: 25.1,
              status: 'delivered',
              createdAt: dayjs().subtract(10, 'minutes').toDate(),
            },
            {
              id: 'ORD-004',
              customer: { name: 'Alexa Richardson' },
              amount: 10.99,
              status: 'refunded',
              createdAt: dayjs().subtract(10, 'minutes').toDate(),
            },
            {
              id: 'ORD-003',
              customer: { name: 'Anje Keizer' },
              amount: 96.43,
              status: 'pending',
              createdAt: dayjs().subtract(10, 'minutes').toDate(),
            },
            {
              id: 'ORD-002',
              customer: { name: 'Clarke Gillebert' },
              amount: 32.54,
              status: 'delivered',
              createdAt: dayjs().subtract(10, 'minutes').toDate(),
            },
            {
              id: 'ORD-001',
              customer: { name: 'Adam Denisov' },
              amount: 16.76,
              status: 'delivered',
              createdAt: dayjs().subtract(10, 'minutes').toDate(),
            },
          ]}
          sx={{ height: '100%' }}
        />
      </Grid> */}
    </Grid>
  );
}
