import storage from "redux-persist/lib/storage";

export const profileDetailsConfig = {
  key: "profileDetailsNew",
  storage,
  whitelist: ["profileDtails"],
};

export const loginDetailsConfig = {
  key: "loginDetailsNew",
  storage,
  whitelist: ["loginDetails"],
};
export const logoutDetailsConfig = {
  key: "logoutDetails",
  storage,
  whitelist: ["logoutDetails"],
};
export const qrDetailsConfig = {
  key: "qrDetailsNew",
  storage,
  whitelist: ["qrDetails"],
};

export const forgotPassDetailsConfig = {
  key: "forgotPassDetailsNew",
  storage,
  whitelist: ["forgotPassDetails"],
};

export const resetPassDetailsConfig = {
  key: "resetPassDetailsNew",
  storage,
  whitelist: ["resetPassDetails"],
};
