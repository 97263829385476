import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGO_URL } from "../constants/stringConstants";
import InputComponent from "../components/input/index";
import { RootState, AppDispatch } from "../redux/store";
import { QrVerification } from "../redux/toolkit/auth/operation";
import "./index.css";
import { PathStrConst } from "../routes/strconstpath";

const Verfication = () => {
  const [token, setToken] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [userPhone] = useState(location.state.data.phoneNo);
  const [userEmail] = useState(location.state.data.email);
  const navigate = useNavigate();

  const qrDetailss: any = useSelector((state: RootState) => state.qrDetails);

  const dispatch = useDispatch<AppDispatch>();

  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setLoading(true);
    e.preventDefault();
    const payload = {
      phoneNo: userPhone,
      email: userEmail,
      token: token,
    };
    dispatch(QrVerification(payload)).then(() => setLoading(false));
  };
  useEffect(() => {
    if (qrDetailss && Object.keys(qrDetailss.data).length !== 0) {
      const token = qrDetailss.data.data.accessToken;
      localStorage.setItem("token", token);
      const userId = qrDetailss.data?.data.user?._id;
      localStorage.setItem("userId", userId);
      const firstName = qrDetailss.data?.data.user.firstName;
      localStorage.setItem("firstName", firstName);
      const lastName = qrDetailss.data?.data.user.lastName;
      localStorage.setItem("lastName", lastName);
      if (localStorage.getItem("token")) {
        navigate(PathStrConst.home, { state: qrDetailss.data });
      }
    }
  }, [qrDetailss]);
  return (
    <div className="Verification">
      <div className="left">
        <div className="logo-box">
          <div className="logo-wrapper">
            <img
              className="logo"
              style={{ width: 208, height: 80 }}
              src={LOGO_URL}
              alt="One Money Way"
            ></img>
          </div>
        </div>
        <div className="left-title-box">
          <h2 className="left-title">The Payment </h2>
          <h2 className="left-title-second">Platform</h2>
          <h2 className="left-title-third">for SMEs</h2>
        </div>
        <div className="welcome-box">
          <p className="welcome">Welcome</p>
          <p>Just a couple of clicks and we begin</p>
        </div>
        <div className="circle-box">
          <div className="blue-circle"></div>
          <div className="white-circle"></div>
        </div>
      </div>
      <div className="ver-right">
        <div className="point-box">
          <p className="left-point"></p>
          <p className="middle-point"></p>
          <p className="right-point"></p>
        </div>
        <h2 className="ver-right-title">Identity verification</h2>
        <p className="enter-text">
          Enter the verification code from Authenticator
        </p>
        {location.state.data.qr ? <img src={location.state.data.qr} /> : " "}
        <form className="verification-form">
          <InputComponent
            className="enter-inp"
            placeholder="Enter code"
            onChange={(e) => setToken(e.target.value)}
          />
          <button className="send-btn" onClick={handleButtonClick}>
            {loading ? <Spinner animation={"border"} /> : "Send"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Verfication;
