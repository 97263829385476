import {
  ProfileDetailsPayload,
  ProfileDetailsResponse,
  LoginDataPayload,
  LoginResponse,
  QrPayload,
  QrResponse,
  ForgotPassPayload,
  ForgotPassResponse,
  ResetPassPayload,
  ResetPassResponse,
  VerifyTokenPayload,
  VerifyTokenResponse,
  LogoutResponse,
  LogoutPayload,
  transactionResponse,
  transactionPayload
} from "./interface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";
import Swal from "sweetalert2";

export const UserRegistration = createAsyncThunk<
  ProfileDetailsResponse,
  ProfileDetailsPayload
>("profileDetails/getprofileDetails", async (payload) => {
  const dataBody = {
    companyName: payload.companyName,
    companyAddress: payload.companyAddress,
    email: payload.email,
    phoneNo: payload.phoneNo,
    password: payload.password,
    firstName: payload.firstName,
    lastName: payload.lastName,
  };
  try {
    const data = await apiHelper("user/register", "post", dataBody);
    Swal.fire({
      icon: "success",
      title: "Registered !!",
      text: "User has been Registered successfully",
    });

    return data;
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oopss",
      text: "Something went wrong while registering",
    });
    throw new Error();
  }
});

export const UserLogin = createAsyncThunk<LoginResponse, LoginDataPayload>(
  "loginDetails/getLoginDetails",
  async (payload) => {
    const dataBody = {
      email: payload.email,
      phoneNo: payload.phoneNo,
      password: payload.password,
    };
    try {
      const data = await apiHelper("user/login", "post", dataBody);
      data.data.data.email = payload.email;
      data.data.data.phoneNo = payload.phoneNo;
      return data.data;
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Oopss",
        text: error,
      });
      throw new Error();
    }
  }
);

export const QrVerification = createAsyncThunk<QrResponse, QrPayload>(
  "qrDetails/getQrDetails",
  async (payload) => {
    const dataBody = {
      email: payload.email,
      phoneNo: payload.phoneNo,
      token: payload.token,
    };
    let data: any = {};
    try {
      data = await apiHelper("user/verifyQr", "post", dataBody);

      return data.data;
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Oopss",
        text: "2Fa not verfied",
      });
    }
    return data;
  }
);

export const ForgotPasswordOp = createAsyncThunk<
  ForgotPassResponse,
  ForgotPassPayload
>("forgotPassDetails/getForgotPassDetails", async (payload) => {
  const dataBody = {
    email: payload.email,
  };
  try {
    const data = await apiHelper("user/forgetPassword", "post", dataBody);
    Swal.fire({
      icon: "success",
      title: "Email sent",
      text: "Your email has been sent successfully",
    });

    return data.data;
  } catch (error: any) {
    Swal.fire({
      icon: "error",
      title: "Oopss",
      text: error,
    });
    throw new Error();
  }
});

export const ResetPasswordOp = createAsyncThunk<
  ResetPassResponse,
  ResetPassPayload
>("resetPassDetails/getResetPassDetails", async (payload) => {
  const dataBody = {
    password: payload.password,
  };
  try {
    const data = await apiHelper(
      `user/resetPassword/${payload.token}`,
      "post",
      dataBody
    );
    Swal.fire({
      icon: "success",
      title: "Password reset",
      text: "Your password has been updated successfully",
    });

    return data.data;
  } catch (error: any) {
    Swal.fire({
      icon: "error",
      title: "Oopss",
      text: "Invalid token",
    });
    throw new Error();
  }
});

export const VerifyToken = createAsyncThunk<
  VerifyTokenResponse,
  VerifyTokenPayload
>("VerifyTokenDetails/getVerifyTokenDetails", async (payload) => {
  const data = await apiHelper(`user/verify/${payload.token}`, "get");
  return data.data;
});

export const Logout = createAsyncThunk<LogoutResponse, LogoutPayload>(
  "LogoutDetails/getLogoutDetails",
  async (token) => {
    const data = await apiHelper(`user/logout/${token}`, "get");
    return data.data;
  }
);

export const FetchLatestTransactions = createAsyncThunk<transactionResponse, transactionPayload>(
  "fetchLatestTransactions/fetchLatestTransactionss",
  async (payload) => {
    const dataBody = {
      merchantId: payload.merchantId,
      merchantName: payload.merchantName,
    
    };
    try {
      const data = await apiHelper("shift4cards/getMerchantDataFromLastYearUntilToday", "post", dataBody);
     
      return data.data;
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Oopss",
        text: error,
      });
      throw new Error();
    }
  }
);
