import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = ({ redirectTo = "/users" }: any) => {
  const isLoggedIn = localStorage.getItem("token");

  return isLoggedIn ? <Navigate to={redirectTo} /> : <Outlet />;
};

export default PublicRoute;
