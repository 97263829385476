import React from "react";
import { PathStrConst } from "./strconstpath";
import SignIn from "../signIn";
import Verification from "../verfication";
import Dashboard from "../dashboard";
import Home from "../pages/home";
import Accounts from "../accounts";
import Roles from "../roles";
import Permissions from '../permissions'
import Merchants from "../pages/merchants";

export const publicRoutes = [
  { path: PathStrConst.signin, component: <SignIn /> },
  { path: PathStrConst.verification, component: <Verification /> },
];

export const privateR = [
  { path: PathStrConst.users, component: <Dashboard /> },
  { path: PathStrConst.home, component: <Home /> },
  { path: PathStrConst.accounts, component: <Accounts /> },
  { path: PathStrConst.role, component: <Roles /> },
  { path: PathStrConst.permission, component: <Permissions /> },
  { path: PathStrConst.merchants, component: <Merchants /> },
];
