interface ProgressBarProps {
    progress: number;
  }
function ProgressBar({ progress }: ProgressBarProps) {
    return (
      <div style={{ width: '100%', backgroundColor: '#e0e0e0' }}>
        <div style={{ width: `${progress}%`, backgroundColor: '#0097f4', height: '5px' }} />
      </div>
    );
  }

export default ProgressBar;
