import React from "react";
import "./index.css";

type InputProps = {
  disabled?: boolean;
  inputStyle?: object;
  name?: string;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  type?: string;
  value?: string;
  defaultValue?:string;
  className?: string;
  errorMsg?: string;
  errorMsgColor?: string;
  min?: string;
}

const InputComponent = (props: InputProps) => {
  return (
    <div>
      <div>
        <input
          className="enter-num-inp"
          tabIndex={0}
          type={props.type}
          name={props.name}
          onChange={props.onChange}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
          style={props.inputStyle}
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          readOnly={props.readOnly}
          required={props.required}
          min={props.min}
          onKeyPress={props.onKeyPress}
        />
      </div>
      {props.errorMsg ? (
        <span
          style={{ color: props.errorMsgColor ? props.errorMsgColor : "red" }}
        >
          {props.errorMsg}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default InputComponent;
