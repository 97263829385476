import OMWLogoWhite from "../assets/images/omw-logo-White.png";
export const regexStrConst = {
  //Required format: johndoe@mail.com
  emailRegex: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.]+\.[a-zA-Z]+$/,
  // eslint-disable-next-line no-useless-escape
  phoneNumberRegex: /^\+[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
  //Requirements: min. char. limit = 8, upper and lower case alphanumeric
  // eslint-disable-next-line no-useless-escape
  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})/,
  amountRegex: /^(^\d*\.?\d*[1-9]+\d*$)|(^[1-9]+\.?\d*$)/,
};

export const errorMsgs = {
  emptyEmail: "Please enter a valid email",
  emptyInput: "Please enter a valid email or phone number",
  emptyPasswordReg:
    "Password must be 8 characters long including capital letters, small letters, special character and number",
  emptyPassword: "Password does not meet the requirements",
  incorrectEmail: "Email not valid",
  incorrectPassword: "Password does not match the requirements",
  emptyAmount: "Please enter a valid amount",
  emptyPaymentRefError: "Please enter a valid paymentRef",
  emptyCurrency: "Please enter a currency",
  emptyBenCurrency: "Please enter a currency",
  emptyName: "Please enter a name",
  emptyPhone: "Please enter a valid phone number",
  emptyAccount: "Please enter a account number",
  emptyNewField: "This field cannot be empty",
  emptyAddress: "Please enter a address",
  emptyCity: "Please enter a city",
  emptyPostCode: "Please enter a postal code",
  emptyCountry: "Please enter a country",
  emptySortCode: "Please enter a sort code",
  emptyRoutingNumber: "Please enter a routing number",
  emptySwiftCOde: "Please enter a Swift Code",
  emptyBeneficiary: "Please select a recipient",
  emptyDescription: "Please enter comments",
};

export const LOGO_URL = 'https://bkimage.onemoneyway.com/omw-logo.png';
export {OMWLogoWhite};