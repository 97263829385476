import React, { useEffect, useState } from "react";
import { Row, Col, Navbar, Dropdown, TabContainer } from "react-bootstrap"; // Import Dropdown component from react-bootstrap
import axios from "axios";
import BASE_URL from "../../constants/baseURL";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  Logout,
  FetchLatestTransactions,
} from "../../redux/toolkit/auth/operation";
import { useNavigate } from "react-router-dom";
import DefaultSidebar from "../../components/sidebar";
import { CircularProgress } from "@mui/material";
import MUIDataTable, { MUIDataTableColumn } from "mui-datatables";
import { Button, Container, Form, Modal, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import Header from "../../components/common/header";
import ProgressBar from "../../components/common/progressbar";
import { Typography } from "@mui/material";

const Merchants = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

    const [merchantList, setMerchantList] = useState<Merchant[]>([]);
    const [userList, setUserList] = useState([]);
    const userLogout: any = useSelector((state: RootState) => state.qrDetails);
    const [loading, setLoading] = useState(false);
    const [showAddMerchantForm, setShowAddMerchantForm] = useState(false);
    const [merchantId, setMerchantId] = useState('');
    const [merchantName, setmerchantName] = useState('');
    const [userIdToAddMerchant, setuserIdToAddMerchant] = useState('');
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [progress, setProgress] = useState(0);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);

    const Token: any = localStorage.getItem('token');
    interface Merchant {
        _id: string;
        merchantId: string;
        merchantName:string;
        userId: string;
        dataFetchedUntil: string;
        // Add other properties as needed
    }

  const merchantsHeader: MUIDataTableColumn[] = [
    { name: "_id", label: "ID", options: { display: "excluded" } },
    { name: "merchantId", label: "Merchant Id" },
    { name: "userId", label: "User Id" },
    { name: "dataFetchedUntil", label: "Data Fetched Until" },
  ];

  const usersHeader: MUIDataTableColumn[] = [
    { name: "email", label: "Email" },
    { name: "phoneNo", label: "Phone No" },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (_, tableMeta) => {
          const userId = tableMeta.rowData[3]; // Assuming _id is at index 9

          // console.log("userId",userId)
          return (
            <Button onClick={() => handleButtonClick(userId)}>
              Add Merchant
            </Button>
          );
        },
      },
    },
    // { name: "activeStatus", label: "Status" },
    // { name: "createdAt", label: "Created" },
    { name: "_id", label: "_id", options: { display: "excluded" } },
  ];

  async function fetchAllMerchantsAPI() {
    try {
      const response: any = await axios.get(
        `${BASE_URL}shift4cards/getMerchants`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      setMerchantList(response.data.data);
    } catch (error) {
      console.error("Error fetching merchants:", error);
    } 
  }

    async function addMerchant(merchantId: any, userId: any, merchantName: any) {
        try {
            const response: any = await axios.post(`${BASE_URL}shift4cards/addMerchant`, {
                merchantId: merchantId,
                userId: userId,

                merchantName: merchantName

            }, {
                headers: {
                    "Authorization": `Bearer ${Token}`
                }
            });

      return response;
    } catch (error) {
      console.error("Error fetching merchants:", error);
    }
  }

  async function getAllUsers() {
    setLoadingUsers(true);
    setInitialLoading(true);
    setProgress(0);
    const updateProgress = (event: any) => {
      if (event.total) {
        setProgress(Math.round((event.loaded / event.total) * 100));
      }
    };
    try {
      const response: any = await axios.get(`${BASE_URL}user/getAllUsers`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
          onDownloadProgress: updateProgress,
          onUploadProgress: updateProgress,
      });
      setUserList(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setProgress(100);
      setLoadingUsers(false);
      setInitialLoading(false);
    }
  }

    const handleFetchData = async (merchantId: string,merchantName:string) => {
        const payload = {
            merchantId: merchantId,
            merchantName:merchantName
        };


    let buttonElement;
    try {
      buttonElement = document.getElementById(`fetchButton-${merchantId}`);
      if (buttonElement) {
        buttonElement.innerHTML = "<span>Loading...</span>";
        buttonElement.setAttribute("disabled", "true");
      }

      const response: any = await dispatch(FetchLatestTransactions(payload));

      console.log("responssesss", response.payload.data);

      if (response.payload.data === "success") {
        Swal.fire("Success", "Data has been fetched.", "success");
      } else {
        Swal.fire("Error", "Failed to fetch data.", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire("Error", "Failed to fetch data.", "error");
    } finally {
      if (buttonElement) {
        buttonElement.innerHTML = "Fetch Data";
        buttonElement.removeAttribute("disabled");
      }
    }
  };

    const handleCloseAddMerchantForm = () => setShowAddMerchantForm(false);
    const handleSubmitMerchantForm = async () => {

        console.log('Merchant Id:', merchantId);
        console.log('Password:', merchantName);
        console.log('user id to add merchant', userIdToAddMerchant);

        const response: any = await addMerchant(merchantId, userIdToAddMerchant, merchantName)
        console.log("response", response);
        setShowAddMerchantForm(false);

    if (response.data.data === "success") {
      Swal.fire("Success", "Merchant Added successfully", "success");
      fetchAllMerchantsAPI();
      getAllUsers();
    } else {
      Swal.fire("Error", "Error while adding Merchant.", "error");
    }


        setMerchantId('')
        setmerchantName('')

    }
    const handleButtonClick = (userId: any) => {
        setShowAddMerchantForm(true); // Show the modal form
        setuserIdToAddMerchant(userId)
    };



  useEffect(() => {
    fetchAllMerchantsAPI();
    getAllUsers();
  }, []);

  useEffect(() => {
    if (userLogout.data === "") {
      localStorage.clear();
      navigate("/");
    }
  }, [userLogout]);

  const renderExpandableRow = (rowData: any, rowMeta: any) => {
    const userId = rowData[3];
    const userMerchants = merchantList.filter(
      (merchant) => merchant.userId === userId
    );

    if (userMerchants.length === 0) {
      return null;
    }

    return (
      <div style={{ width: "250px" }}>
        <table
          style={{
            width: "100%",
            marginBottom: "20px",
            borderCollapse: "collapse",
          }}
        >
          <thead style={{ width: "250px" }}>
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "center",
                  width: "250px",
                }}
              >
                Merchant Id
              </th>
              {/* <th style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'center', width: '250px' }}>Data Fetched Until</th>
               */}
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "center",
                  width: "250px",
                  whiteSpace: "nowrap",
                }}
              >
                Data Fetched Until
              </th>

              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "center",
                  width: "250px",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody style={{ width: "250px" }}>
            {userMerchants.map((merchant) => (
              <tr key={merchant._id}>
                <td
                  style={{
                    borderBottom: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {merchant.merchantId}
                </td>
                <td
                  style={{
                    borderBottom: "1px solid #ddd",
                    padding: "8px",
                    width: "100px",
                    marginLeft: "50px",
                    textAlign: "center",
                  }}
                >
                  {merchant.dataFetchedUntil
                    ? merchant.dataFetchedUntil.split("T")[0]
                    : ""}
                </td>

                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px',width:'20px' }}>
                                    <Button
                                        id={`fetchButton-${merchant.merchantId}`}
                                        onClick={() => handleFetchData(merchant.merchantId,merchant.merchantName)}
                                        disabled={isFetchButtonDisabled(merchant.dataFetchedUntil)}
                                        style={{width:'101px'}}
                                    >
                                        Fetch Data
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

  const isFetchButtonDisabled = (dataFetchedUntil: any) => {
    if (dataFetchedUntil === undefined) {
      return false;
    }

    const today = new Date().toISOString().split("T")[0];
    const extractedDate = dataFetchedUntil.split("T")[0];
    console.log("dataFetchedUntil", dataFetchedUntil);
    console.log("today", today);
    return extractedDate === today;
  };

  return (
    <Container fluid className="App">
      <Row>
        <Col
          style={{ backgroundColor: "white", minHeight: "", color: "white" }}
          lg={2}
        >
          <DefaultSidebar />
        </Col>
        <Col
          style={{ backgroundColor: "#ececec" }}
          sm={12}
          xl={10}
          xs={12}
          md={12}
          lg={10}
        >
          <Header />
          {initialLoading || loadingUsers ? (
            <div
              className="d-flex justify-content-between gap-2  align-items-center"
              style={{ alignItems: "flex-start" }}
            >
              <ProgressBar progress={progress} />
              <Typography>{progress}%</Typography>
            </div>
          ) : !loadingUsers && merchantList.length === 0 ? (
            <h3>Oops, something went wrong</h3>
          ) : (
              <div style={{ margin: 10 }}>
              <MUIDataTable
                title={"Merchants List"}
                data={userList}
                columns={usersHeader}
                options={{
                  filter: true,
                  filterType: "dropdown",
                  responsive: "standard",
                  expandableRows: true,
                  expandableRowsHeader: false,
                  expandableRowsOnClick: true,
                  isRowExpandable: (dataIndex, expandedRows) => {
                    return true;
                  },
                  renderExpandableRow: renderExpandableRow,
                }}
              />
              {loading && <CircularProgress />}
            </div>
          )}

                    <Modal show={showAddMerchantForm} onHide={handleCloseAddMerchantForm}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: 15, textAlign: 'center', alignContent: 'center' }}>Add Merchant</Modal.Title>
                        </Modal.Header>
                        <Form >
                            <Modal.Body>
                                <Form.Group className="mb-3" controlId="MerchantID">
                                    <Form.Label>Merchant Id</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Merchant Id"
                                        value={merchantId}
                                        onChange={(e) => setMerchantId(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="Password">
                                    <Form.Label>Merchant Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Merchant Name"
                                        value={merchantName}
                                        onChange={(e) => setmerchantName(e.target.value)}
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseAddMerchantForm} type="button">
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleSubmitMerchantForm}>
                                    Submit
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                </Col>
            </Row>
        </Container>
    );
};

export default Merchants;
